<template>
  <section></section>
</template>

<script>
import { authManagers } from '@/$plugins/auth'

export default {
  name: 'Login',
  beforeCreate () {
    authManagers.login()
  }
}
</script>
